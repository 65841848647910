
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align: left"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        :label-width="'100px'"
      >
        <el-form-item label="编号" prop="id">
          <el-input
            v-model="formModel.id"
            placeholder="请输入编号"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input
            v-model="formModel.title"
            placeholder="请输入标题"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="内容" prop="content">
          <el-input
            v-model="formModel.content"
            placeholder="请输入内容"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="封面" prop="frontCover">
          <el-input
            v-model="formModel.frontCover"
            placeholder="请输入封面"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="起始时间" prop="startTime">
          <el-input
            v-model="formModel.startTime"
            placeholder="请输入起始时间"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="截止时间" prop="endTime">
          <el-input
            v-model="formModel.endTime"
            placeholder="请输入截止时间"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="申请人" prop="applicant">
          <el-input
            v-model="formModel.applicant"
            placeholder="请输入申请人"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="房号" prop="roomNumber">
          <el-input
            v-model="formModel.roomNumber"
            placeholder="请输入房号"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="申请时间" prop="applicationTime">
          <el-input
            v-model="formModel.applicationTime"
            placeholder="请输入申请时间"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="审核是否通过(0-待审核,1-已批准,2-未批准)"
          prop="verify"
        >
          <el-input
            v-model="formModel.verify"
            placeholder="请输入审核是否通过(0-待审核,1-已批准,2-未批准)"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否删除" prop="delFlag">
          <el-input
            v-model="formModel.delFlag"
            placeholder="请输入是否删除"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="createBy">
          <el-input
            v-model="formModel.createBy"
            placeholder="请输入"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="createTime">
          <el-input
            v-model="formModel.createTime"
            placeholder="请输入"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="updateBy">
          <el-input
            v-model="formModel.updateBy"
            placeholder="请输入"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="" prop="updateTime">
          <el-input
            v-model="formModel.updateTime"
            placeholder="请输入"
            style="width: 300px"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import activityInfoApi from "@/api/base/activityInfo";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      formModel: {},
      ruleValidate: {
        id: [{ required: true, message: "编号不能为空", trigger: "blur" }],
        title: [{ required: true, message: "标题不能为空", trigger: "blur" }],
        content: [{ required: true, message: "内容不能为空", trigger: "blur" }],
        frontCover: [
          { required: true, message: "封面不能为空", trigger: "blur" },
        ],
        startTime: [
          { required: true, message: "起始时间不能为空", trigger: "blur" },
        ],
        endTime: [
          { required: true, message: "截止时间不能为空", trigger: "blur" },
        ],
        applicant: [
          { required: true, message: "申请人不能为空", trigger: "blur" },
        ],
        roomNumber: [
          { required: true, message: "房号不能为空", trigger: "blur" },
        ],
        applicationTime: [
          { required: true, message: "申请时间不能为空", trigger: "blur" },
        ],
        verify: [
          {
            required: true,
            message: "审核是否通过(0-待审核,1-已批准,2-未批准)不能为空",
            trigger: "blur",
          },
        ],
        delFlag: [
          { required: true, message: "是否删除不能为空", trigger: "blur" },
        ],
        createBy: [{ required: true, message: "不能为空", trigger: "blur" }],
        createTime: [{ required: true, message: "不能为空", trigger: "blur" }],
        updateBy: [{ required: true, message: "不能为空", trigger: "blur" }],
        updateTime: [{ required: true, message: "不能为空", trigger: "blur" }],
      },
      showDialog: true,
      loading: false,
      submitting: false,
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return activityInfoApi.add(self.formModel);
            } else {
              return activityInfoApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return activityInfoApi.create();
      } else {
        return activityInfoApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
};
</script>